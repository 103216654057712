.graficoObjetivosContainer{
    margin-bottom: 1rem;
}
.flex{
    display: flex;
}
.contedorObjetivo{
    padding:5px;
}

.tituloGraficoObjetivos{
    font-size: 22px;
    font-weight: bold;
    color: #2c787c;
    margin:0;
}

.graficoObjetivosDatosContainer{
   
    height: 300px;
}
