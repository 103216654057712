.InfoCambiosContainer{
    overflow: auto;
}
.InfoCambiosTitulo{
    margin:0;
    padding-left: 5px;
}
.InfoCambiosGrande{
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
}
.infoCambiosMensaje{
    margin-top:10px;
    margin-bottom: 10px;
}

.InfoCambiosVerde{
    padding-left: 10px;
    color:#2c787c;
    font-weight: bold;
}

.heHechoCambiosBoton{
    margin:15px 5% 5px;
    width: 90%;
    padding: 5px 15px;
    border-radius: 18px;
    border-width: 0px;
    font-family: Asap;
    font-size: 16px;
    text-align: center;
    background-color: #f05759;
    color:white
}
