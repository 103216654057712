.titledudas
{
    color: #2c787c;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 15px;
    width: 100%;
    text-align: center;
}
.panelDudasContainer
{
    margin-bottom: 50px;
}
.greenText
{
    color: #2c787c;
    font-size: 16px;
}
.dudasText
{
    color: #3f3f3f;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    width: 100%;
}
.dudasSmallText
{
    color: #3f3f3f;
    font-size: 16px;
    text-align: center;
    width: 100%;
}
.smallerText
{
    margin-top: 30px !important;
    color: #3f3f3f;
    font-size: 14px;
    width: 100%;
    text-align: center;
}
.buttonContainerDudas
{
    width: 100%;
    text-align: center;
    align-items: center;
    margin-top: 15px;
}
.contactButton
{
    margin: auto;
    width: 180px;
    height: 43px;
    padding: 10px 6px 12px 7px;
    border-radius: 21.5px;
    background-color: rgba(50, 211, 102,0.2);
    text-align: center;
    color:#2c787c;
}
.telefono
{
    color: #25d366;
    font-size: 20px;
    margin-top: 15px;
}
.whatsapp
{
    background-color: rgba(50, 211, 102,0.2);
    color:#2c787c;
}
.whatsapp:hover
{
    cursor: pointer;
}
.llamada
{
    background-color: rgba(48, 178, 184,0.2);
    color:#2c787c;
}
.llamada:hover
{
    cursor: pointer;
}
.contactimage
{
    height: 70px;
    margin-bottom: 20px;
}