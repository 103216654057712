.sombreado  {
   
    padding: 1.25rem;
    border-radius: 1rem;
    box-shadow: 8px 8px 30px 0 rgba(11, 57, 59, 0.18);
    background-color: var(--white);
}
.asapText
{
    font-family: Asap;
}