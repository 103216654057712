.titlechart
{
    color: #2c787c;
    font-size: 1.375rem;
    font-weight: bold;
    margin-bottom: 1.25rem;
    margin-top: 1rem;
}

.smallerText
{
    margin-top: 30px !important;
    color: #3f3f3f;
    font-size: 14px;
    width: 100%;
    text-align: center;
}

.textonormal
{
    color: #3f3f3f;
    font-size: 14px;
    
}

.enLinea
{
    display:flex; flex-direction:row;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
.video-responsive iframe {
left: 0;
top: 0;
height: 100%;
width: 100%;
position: absolute;
}